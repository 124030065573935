<template>
  <div class="boss">
    <div class="bgc">
      <div class="video" id="video-container"></div>
      <div v-if="type == 0">
        <div class="text" v-for="item in list" :key="item.id">
          <ul class="info">
            <li>
              领养编号： <span class="left">{{ item.id }}</span>
            </li>
            <li>
              当前猪龄：<span class="left">{{ item.month }} 月</span>
            </li>
            <li>
              当前重量：<span class="left">{{ item.weight }} KG</span>
            </li>
            <li style="display: flex">
              {{ item.pigpen + "：" }}
              <span class="left" v-html="item.pigpen_content"> </span>
            </li>
          </ul>
          <div class="certificate" v-if="item.prevent">
            <p class="record">防疫记录</p>
            <div class="infoText">
              <div
                class="content"
                v-for="items in item.prevent"
                :key="items.id"
              >
                <p class="time">{{ items.add_time }}</p>
                <p class="medicine">{{ items.prevention }}</p>
              </div>
            </div>
          </div>
          <div class="bottom">
            <div
              class="btn"
              @click="goBuy(item.price, item.total, item.earnest_money)"
            >
              <p>{{ item.price }}</p>
              <img src="../../assets/img/jb.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="isYuyue" v-else>
        <ul class="infos">
          <li style="display: flex">
            <span class="minWidth">{{ list.pigpen || +"：" }}</span>
            <span class="left" v-html="list.pigpen_content"> </span>
          </li>
        </ul>
        <div class="redText">
          注：预约成功后3~7个工作日可付尾款领养,先到
          先得。预约成功不可取消，敬请理解。
        </div>
        <img src="../../assets/img/yuyue.png" @click="gorReserve()" alt="" />
      </div>
      <div class="close" @click="pageClose">
        <img src="../../assets/img/close.png" alt="" />
      </div>
    </div>
    <yu-yue
      :popshow="popshow"
      @closePop="closePop"
      :yuMoney="yuMoney"
      @adoption="adoption"
    ></yu-yue>
    <gold :goldShow="goldShow" @goldUpdate="goldUpdate"></gold>
    <van-popup v-model="networkAnomaly" :close-on-click-overlay="false">
      <div class="yichang">
        <p class="title">温馨提示</p>
        <div class="info">
          <p>网络已断开</p>
          <p>请退出重新打开...</p>
        </div>
        <p class="kown" @click="tuichu">我知道了</p>
      </div>
    </van-popup>
  </div>
</template>

<script>
import EZUIKit from "ezuikit-js";
import config from "../../common/config";
import { getDetail } from "../../common/api";
import yuYue from "../../components/yuyue.vue";
import { reservation } from "../../common/api";
import gold from "../../components/gold.vue";

export default {
  components: {
    yuYue,
    gold,
  },
  data() {
    return {
      accessToken: "", // 萤石云Token
      url: "",
      list: [],
      type: this.$route.query.type,
      popshow: false,
      goldShow: false, //金币
      yuMoney: 0,
      timing: 0,
      networkAnomaly: false,
      timer: null,
    };
  },
  created() {
    this.dataInit();
    setTimeout(() => {
      this.getToken();
    }, 500);
  },
  methods: {
    tuichu() {
      this.networkAnomaly = false;
      console.log("点击关闭");
      this.$router.go(-1);
    },
    absolute() {
      console.log(11);
    },
    // 金币不足
    goldUpdate: (function () {
      let timer = null;
      return function () {
        clearTimeout(timer);
        timer = setTimeout(() => {
          this.goldShow = !this.goldShow;
        }, 300);
      };
    })(),
    // 数据初始化
    async dataInit() {
      let data = {
        access_id: this.$store.state.access_id,
        p_id: this.$store.state.p_id,
      };
      let res = await getDetail(data);
      if (res.data.code != 200) return;
      if (this.type == 1) {
        this.list = res.data.data;
      } else {
        this.list.push(res.data.data);
      }
      this.yuMoney = res.data.data.deposit_money;
      this.url = res.data.data.monitor_url;
    },
    async getToken() {
      // 发送 POST 请求
      await this.$axios({
        method: "post",
        url: config.ysyURL,
        params: {
          appKey: config.appKey,
          appSecret: config.appSecret,
        },
      }).then((res) => {
        if (res.data.code != 200) return;
        this.accessToken = res.data.data.accessToken;
      });
      //视频初始化
      this.player = await new EZUIKit.EZUIKitPlayer({
        autoplay: true, //自动播放
        id: "video-container", // 视频容器ID
        accessToken: this.accessToken,
        url: this.url,
        audio: 0,
      });
      this.timer = setInterval(() => {
        this.timing += 60000;
        if (this.timing >= 600000) {
          this.stopVideo();
          this.networkAnomaly = true;
          console.log("结束播放");
          clearInterval(this.timer);
          return;
        }
      }, 60000);
    },
    // 停止播放
    stopVideo() {
      this.player.stop();
    },
    // 领养协议 价格， 总价 保证金
    goBuy(price, total, money) {
      this.$router.push({
        path: "/protocol",
        query: {
          price: price,
          total: total,
          money: money,
        },
      });
    },
    pageClose() {
      console.log("点击关闭");
      this.$router.go(-1);
    },
    // 去预约
    gorReserve() {
      this.popshow = true;
    },
    // 关闭预约弹窗
    closePop(val) {
      this.popshow = val;
    },
    adoption: (function () {
      let timer = null;
      return function () {
        clearTimeout(timer);
        timer = setTimeout(() => {
          reservation({ access_id: this.$store.state.access_id }).then(
            (res) => {
              if (res.data.code == "80001") {
                //金币不足
                this.popshow = !this.popshow;
                this.goldShow = true;
              }
              if (res.data.code == 200) {
                this.popshow = !this.popshow;
                this.$router.go(-1);
              }
            }
          );
        }, 300);
      };
    })(),
  },
};
</script>

<style lang="scss" scoped>
.boss {
  min-height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(#000, 0.1);
  .bgc {
    width: 100%;
    height: 14.5rem;
    background: url("../../assets/img/yangya.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  .video {
    width: 6.3rem;
    min-height: 4rem;
    background: #000;
    margin-top: 1.7rem;
  }
  .text {
    width: 6.2rem;
    .info {
      font-size: 0.3rem;
      font-weight: 500;
      color: #945614;
      li {
        margin: 0.2rem 0;
      }
      .left {
        margin-left: 0.5rem;
        font-weight: 600;
      }
    }
    .certificate {
      width: 100%;
      height: 3.5rem;
      border-radius: 0.2rem;
      background: #ffc693;
      .record {
        height: 0.6rem;
        border-bottom: 1px dashed #d79f6d;
        box-sizing: border-box;
        margin: 0 0.2rem;
        padding: 0 0.2rem;
        font-size: 0.34rem;
        line-height: 0.6rem;
        font-weight: 500;
        color: #f9750c;
      }
      .infoText {
        height: 2.75rem;
        padding: 0 0.2rem;
        overflow: hidden;
        overflow-y: scroll;
        margin-top: 0.1rem;
        .display {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #945614;
        }
        .content {
          height: 0.8rem;
          background: #fbe5ba;
          border-radius: 0.25rem;
          padding: 0.2rem 0.3rem;
          margin: 0.15rem 0;
          .time {
            font-size: 0.3rem;
            color: #945614;
            line-height: 1;
            font-weight: 400;
          }
          .medicine {
            font-size: 0.35rem;
            color: #945614;
            font-weight: 600;
            line-height: 2;
          }
        }
      }
    }
    .bottom {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      // margin-top: 0.2rem;
      position: absolute;
      bottom: 0.8rem;
      left: 0.1rem;
      .btn {
        width: 4rem;
        height: 1.25rem;
        border-radius: 2.12rem;
        line-height: 1rem;
        font-size: 0.5rem;
        color: #fff;
        background: url("../../assets/img/detail.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img {
          width: 0.55rem;
          height: 0.55rem;
          position: absolute;
          right: 0.4rem;
        }
      }
    }
  }
}
.infos {
  width: 6.3rem;
  font-size: 0.3rem;
  font-weight: 500;
  color: #945614;
  display: flex;
  justify-content: flex-start;
  .minWidth {
    min-width: 1.45rem;
  }
  li {
    margin: 0.2rem 0;
  }
  .left {
    margin-left: 0.5rem;
    font-weight: 600;
  }
}
.close {
  position: absolute;
  top: 0.4rem;
  right: 0.9rem;
  img {
    width: 1rem;
    height: 1rem;
    object-fit: contain;
  }
}
.isYuyue {
  width: 6.3rem;
  height: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .redText {
    font-size: 0.3rem;
    text-align: center;
    color: #ff0000;
    margin-top: 4rem;
  }
  img {
    height: auto;
    width: 4rem;
    margin-top: 0.3rem;
  }
}
.van-popup {
  background: none;
}
.yichang {
  width: 5rem;
  height: 5.6rem;
  background: #fde3bc;
  border-radius: 0.2rem;
  overflow: hidden;
  .title {
    font-size: 0.57rem;
    color: #945614;
    text-align: center;
    margin-top: 0.6rem;
  }
  .info {
    font-size: 0.4rem;
    color: #945614;
    margin-top: 0.5rem;
    text-align: center;
    p {
      margin-top: 0.1rem;
    }
  }
  .kown {
    width: 3rem;
    height: 0.87rem;
    background: #945614;
    color: #fff;
    margin: auto;
    margin-top: 0.8rem;
    line-height: 0.86rem;
    text-align: center;
    border-radius: 1rem;
    font-size: 0.4rem;
  }
}
</style>
