<template>
  <div>
    <van-popup v-model="popshow" :close-on-click-overlay="false">
      <div class="boxboss">
        <div class="content">
          <div class="header">
            <img src="../assets/img/yes.png" alt="" />
          </div>
          <div class="yuyue">
            <p class="text">是否花费{{ yuMoney }}金币预约？</p>
            <p class="zhu">
              注：预约成功后3~7个工作日可付尾款领养，先到
              先得。预约成功不可取消，敬请理解。
            </p>
            <div class="btn">
              <div class="cancel" @click="close"></div>
              <div class="confirm" @click="adopt"></div>
            </div>
          </div>
          <div class="close" @click="close">
            <img src="../assets/img/close.png" alt="" />
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    popshow: {
      type: Boolean,
      default: false,
    },
    yuMoney: {
      type: Number,
    },
  },
  data() {
    return {};
  },
  methods: {
    adopt() {
      this.$emit("adoption");
    },
    close: (function () {
      let timer = null;
      return function () {
        clearTimeout(timer);
        timer = setTimeout(() => {
          this.$emit("closePop", false);
        }, 300);
      };
    })(),
  },
};
</script>

<style lang="scss" scoped>
.van-popup {
  background: none;
}
.boxboss {
  width: 9rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .content {
    width: 8rem;
    /* height: 5rem; */
    background: #fbe5ba;
    border-radius: 0.2rem;
    overflow: auto;
    .header {
      height: 1.3rem;
      width: 100%;
      background: #d79f6d;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: auto;
        height: 0.75rem;
      }
    }
    .yuyue {
      width: 100%;
      height: 3.69rem;
      .text {
        font-size: 0.4rem;
        color: #945614;
        text-align: center;
        margin-top: 0.5rem;
      }
      .zhu {
        font-size: 0.32rem;
        color: #fe0000;
        text-align: center;
        margin-top: 0.3rem;
        padding: 0 0.3rem;
      }
      .btn {
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-top: 0.5rem;
        .cancel {
          width: 2.2rem;
          height: 0.9rem;
          background: url("../assets/img/quxiao.png");
          background-position: 100%;
          background-size: 100%;
          background-repeat: no-repeat;
        }
        .confirm {
          width: 2.2rem;
          height: 0.9rem;
          background: url("../assets/img/queding.png");
          background-position: 100%;
          background-size: 100%;
          background-repeat: no-repeat;
        }
      }
    }
  }
  .close {
    position: absolute;
    top: 0rem;
    right: 0.1rem;
    img {
      width: 1rem;
      height: auto;
    }
  }
}
</style>
